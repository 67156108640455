import {LightTheme} from "@mercedes-benz/mui5-theme";
import "@mercedes-benz/typeface-mb-corpo-s";
import "@mercedes-benz/typeface-mb-corpo-a";
import {createTheme, CssBaseline, StyledEngineProvider, Theme, ThemeProvider} from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import "./common/i18n/i18n";
import {PushNotification} from "./common/PushNotification";
import {initAxios} from "./rest/RestHttpClient";
import App from "./views/App";
import {LoadingIndicator} from "./views/LoadingIndicator";

async function main() {
  await initAxios();

  const CustomLightTheme: Theme = {
    ...LightTheme,
    palette: {
      ...LightTheme.palette,
      background: {
        default: "#ffffff",
        paper: "#ffffff"
      }
    }
  };

  ReactDOM.render(
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(CustomLightTheme)}>
        <CssBaseline>
          <App />
        </CssBaseline>
        <PushNotification />
        <LoadingIndicator />
      </ThemeProvider>
    </StyledEngineProvider>,
    document.getElementById("root")
  );
}

main();
