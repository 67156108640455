/* tslint:disable */
/* eslint-disable */
/**
 * WebFromsAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {Configuration} from "./configuration";
import type {AxiosPromise, AxiosInstance, AxiosRequestConfig} from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "./common";
import type {RequestArgs} from "./base";
// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError} from "./base";

/**
 *
 * @export
 * @enum {string}
 */

export const EControlType = {
  Text: "Text",
  Memo: "Memo",
  Number: "Number",
  DateTime: "DateTime",
  Date: "Date",
  Time: "Time",
  Phonenumber: "Phonenumber",
  File: "File",
  Picture: "Picture",
  TimeSpan: "TimeSpan",
  Password: "Password",
  EMail: "EMail",
  Filefolder: "Filefolder",
  Url: "URL",
  Selectionbox: "Selectionbox",
  Checkbox: "Checkbox",
  Document: "Document",
  Rtf: "RTF",
  Html: "HTML",
  TextAutomatic: "TextAutomatic",
  WebBrowser: "WebBrowser"
} as const;

export type EControlType = (typeof EControlType)[keyof typeof EControlType];

/**
 *
 * @export
 * @enum {string}
 */

export const EFieldType = {
  Guid: "GUID",
  Text: "Text",
  Memo: "Memo",
  Boolean: "Boolean",
  DateTime: "DateTime",
  SmallInt: "SmallInt",
  Integer: "Integer",
  Double: "Double",
  TimeSpan: "TimeSpan",
  Expression: "Expression",
  DataTable: "DataTable",
  Date: "Date",
  Time: "Time",
  Blob: "Blob",
  Object: "Object",
  Image: "Image"
} as const;

export type EFieldType = (typeof EFieldType)[keyof typeof EFieldType];

/**
 *
 * @export
 * @interface Form
 */
export interface Form {
  /**
   *
   * @type {string}
   * @memberof Form
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Form
   */
  nameEn: string;
  /**
   *
   * @type {string}
   * @memberof Form
   */
  guid: string;
  /**
   *
   * @type {boolean}
   * @memberof Form
   */
  mailValidationRequired: boolean;
}
/**
 *
 * @export
 * @interface FormDeffinition
 */
export interface FormDeffinition {
  /**
   *
   * @type {string}
   * @memberof FormDeffinition
   */
  formName: string;
  /**
   *
   * @type {string}
   * @memberof FormDeffinition
   */
  formDescription: string;
  /**
   *
   * @type {boolean}
   * @memberof FormDeffinition
   */
  mailValidationRequired: boolean;
  /**
   *
   * @type {Array<FormField>}
   * @memberof FormDeffinition
   */
  fields: Array<FormField>;
}
/**
 *
 * @export
 * @interface FormField
 */
export interface FormField {
  /**
   *
   * @type {string}
   * @memberof FormField
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FormField
   */
  captionDE: string;
  /**
   *
   * @type {string}
   * @memberof FormField
   */
  captionEN: string;
  /**
   *
   * @type {EControlType}
   * @memberof FormField
   */
  controlType: EControlType;
  /**
   *
   * @type {EFieldType}
   * @memberof FormField
   */
  fieldType: EFieldType;
  /**
   *
   * @type {number}
   * @memberof FormField
   */
  pos: number;
  /**
   *
   * @type {string}
   * @memberof FormField
   */
  validationRegex: string;
  /**
   *
   * @type {string}
   * @memberof FormField
   */
  validationMessage: string;
  /**
   *
   * @type {string}
   * @memberof FormField
   */
  lookUpGuid: string;
  /**
   *
   * @type {boolean}
   * @memberof FormField
   */
  required: boolean;
}

/**
 *
 * @export
 * @interface FormValue
 */
export interface FormValue {
  /**
   *
   * @type {string}
   * @memberof FormValue
   */
  fieldName: string;
  /**
   *
   * @type {string}
   * @memberof FormValue
   */
  value: string;
}
/**
 *
 * @export
 * @interface FormValues
 */
export interface FormValues {
  /**
   *
   * @type {Array<FormValue>}
   * @memberof FormValues
   */
  values: Array<FormValue>;
}
/**
 *
 * @export
 * @interface InsertResult
 */
export interface InsertResult {
  /**
   *
   * @type {string}
   * @memberof InsertResult
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof InsertResult
   */
  messageDE: string;
  /**
   *
   * @type {string}
   * @memberof InsertResult
   */
  messageEN: string;
}
/**
 *
 * @export
 * @interface LokUpTableDeffintion
 */
export interface LokUpTableDeffintion {
  /**
   *
   * @type {string}
   * @memberof LokUpTableDeffintion
   */
  lokUpTableName: string;
  /**
   *
   * @type {string}
   * @memberof LokUpTableDeffintion
   */
  lokUpTableNameEN: string;
  /**
   *
   * @type {string}
   * @memberof LokUpTableDeffintion
   */
  guid: string;
}
/**
 *
 * @export
 * @interface LokUpTableValue
 */
export interface LokUpTableValue {
  /**
   *
   * @type {string}
   * @memberof LokUpTableValue
   */
  displayValueDE: string;
  /**
   *
   * @type {string}
   * @memberof LokUpTableValue
   */
  displayValueEN: string;
  /**
   *
   * @type {string}
   * @memberof LokUpTableValue
   */
  guid: string;
}
/**
 *
 * @export
 * @interface StringStringTuple
 */
export interface StringStringTuple {
  /**
   *
   * @type {string}
   * @memberof StringStringTuple
   */
  item1: string;
  /**
   *
   * @type {string}
   * @memberof StringStringTuple
   */
  item2: string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} formGUID
     * @param {string} [fieldName]
     * @param {string} [captionEN]
     * @param {string} [captionDE]
     * @param {EFieldType} [eFieldType]
     * @param {boolean} [required]
     * @param {number} [pos]
     * @param {string} [validationRegex]
     * @param {string} [validationMessage]
     * @param {string} [lookUpGuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addField: async (
      formGUID: string,
      fieldName?: string,
      captionEN?: string,
      captionDE?: string,
      eFieldType?: EFieldType,
      required?: boolean,
      pos?: number,
      validationRegex?: string,
      validationMessage?: string,
      lookUpGuid?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'formGUID' is not null or undefined
      assertParamExists("addField", "formGUID", formGUID);
      const localVarPath = `/api/admin/Admin/forms/{FormGUID}/Addfield`.replace(`{${"FormGUID"}}`, encodeURIComponent(String(formGUID)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "GET", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      if (fieldName !== undefined) {
        localVarQueryParameter["fieldName"] = fieldName;
      }

      if (captionEN !== undefined) {
        localVarQueryParameter["captionEN"] = captionEN;
      }

      if (captionDE !== undefined) {
        localVarQueryParameter["captionDE"] = captionDE;
      }

      if (eFieldType !== undefined) {
        localVarQueryParameter["_eFieldType"] = eFieldType;
      }

      if (required !== undefined) {
        localVarQueryParameter["required"] = required;
      }

      if (pos !== undefined) {
        localVarQueryParameter["pos"] = pos;
      }

      if (validationRegex !== undefined) {
        localVarQueryParameter["ValidationRegex"] = validationRegex;
      }

      if (validationMessage !== undefined) {
        localVarQueryParameter["ValidationMessage"] = validationMessage;
      }

      if (lookUpGuid !== undefined) {
        localVarQueryParameter["LookUpGuid"] = lookUpGuid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} [name]
     * @param {string} [nameEN]
     * @param {string} [description]
     * @param {boolean} [mailValidationRequired]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addForm: async (
      name?: string,
      nameEN?: string,
      description?: string,
      mailValidationRequired?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/Admin/forms/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "POST", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      if (name !== undefined) {
        localVarQueryParameter["Name"] = name;
      }

      if (nameEN !== undefined) {
        localVarQueryParameter["NameEN"] = nameEN;
      }

      if (description !== undefined) {
        localVarQueryParameter["Description"] = description;
      }

      if (mailValidationRequired !== undefined) {
        localVarQueryParameter["MailValidationRequired"] = mailValidationRequired;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} formGUID
     * @param {string} fieldName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletField: async (formGUID: string, fieldName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'formGUID' is not null or undefined
      assertParamExists("deletField", "formGUID", formGUID);
      // verify required parameter 'fieldName' is not null or undefined
      assertParamExists("deletField", "fieldName", fieldName);
      const localVarPath = `/api/admin/Admin/forms/{FormGUID}/DeletField/{fieldName}`
        .replace(`{${"FormGUID"}}`, encodeURIComponent(String(formGUID)))
        .replace(`{${"fieldName"}}`, encodeURIComponent(String(fieldName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "DELETE", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} formGUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dropForms: async (formGUID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'formGUID' is not null or undefined
      assertParamExists("dropForms", "formGUID", formGUID);
      const localVarPath = `/api/admin/Admin/forms/drop/{FormGUID}`.replace(`{${"FormGUID"}}`, encodeURIComponent(String(formGUID)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "DELETE", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/Admin/forms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "GET", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} gUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getForm: async (gUID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'gUID' is not null or undefined
      assertParamExists("getForm", "gUID", gUID);
      const localVarPath = `/api/admin/Admin/forms/{GUID}`.replace(`{${"GUID"}}`, encodeURIComponent(String(gUID)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "GET", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLokUpTableDeffintions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/Admin/data/LokUpTableDeffintions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "GET", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} formGUID
     * @param {string} [fieldName]
     * @param {string} [captionEN]
     * @param {string} [captionDE]
     * @param {boolean} [required]
     * @param {number} [pos]
     * @param {string} [validationRegex]
     * @param {string} [validationMessage]
     * @param {string} [lookUpGuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateField: async (
      formGUID: string,
      fieldName?: string,
      captionEN?: string,
      captionDE?: string,
      required?: boolean,
      pos?: number,
      validationRegex?: string,
      validationMessage?: string,
      lookUpGuid?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'formGUID' is not null or undefined
      assertParamExists("updateField", "formGUID", formGUID);
      const localVarPath = `/api/admin/Admin/forms/{FormGUID}/UpdateField`.replace(`{${"FormGUID"}}`, encodeURIComponent(String(formGUID)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "POST", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      if (fieldName !== undefined) {
        localVarQueryParameter["fieldName"] = fieldName;
      }

      if (captionEN !== undefined) {
        localVarQueryParameter["captionEN"] = captionEN;
      }

      if (captionDE !== undefined) {
        localVarQueryParameter["captionDE"] = captionDE;
      }

      if (required !== undefined) {
        localVarQueryParameter["required"] = required;
      }

      if (pos !== undefined) {
        localVarQueryParameter["pos"] = pos;
      }

      if (validationRegex !== undefined) {
        localVarQueryParameter["ValidationRegex"] = validationRegex;
      }

      if (validationMessage !== undefined) {
        localVarQueryParameter["ValidationMessage"] = validationMessage;
      }

      if (lookUpGuid !== undefined) {
        localVarQueryParameter["LookUpGuid"] = lookUpGuid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} formGUID
     * @param {string} [fieldName]
     * @param {string} [captionEN]
     * @param {string} [captionDE]
     * @param {EFieldType} [eFieldType]
     * @param {boolean} [required]
     * @param {number} [pos]
     * @param {string} [validationRegex]
     * @param {string} [validationMessage]
     * @param {string} [lookUpGuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addField(
      formGUID: string,
      fieldName?: string,
      captionEN?: string,
      captionDE?: string,
      eFieldType?: EFieldType,
      required?: boolean,
      pos?: number,
      validationRegex?: string,
      validationMessage?: string,
      lookUpGuid?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormDeffinition>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addField(
        formGUID,
        fieldName,
        captionEN,
        captionDE,
        eFieldType,
        required,
        pos,
        validationRegex,
        validationMessage,
        lookUpGuid,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [name]
     * @param {string} [nameEN]
     * @param {string} [description]
     * @param {boolean} [mailValidationRequired]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addForm(
      name?: string,
      nameEN?: string,
      description?: string,
      mailValidationRequired?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Form>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addForm(name, nameEN, description, mailValidationRequired, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} formGUID
     * @param {string} fieldName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletField(
      formGUID: string,
      fieldName: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormDeffinition>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletField(formGUID, fieldName, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} formGUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dropForms(
      formGUID: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Form>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dropForms(formGUID, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forms(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Form>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.forms(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} gUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getForm(gUID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormDeffinition>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getForm(gUID, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLokUpTableDeffintions(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LokUpTableDeffintion>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLokUpTableDeffintions(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} formGUID
     * @param {string} [fieldName]
     * @param {string} [captionEN]
     * @param {string} [captionDE]
     * @param {boolean} [required]
     * @param {number} [pos]
     * @param {string} [validationRegex]
     * @param {string} [validationMessage]
     * @param {string} [lookUpGuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateField(
      formGUID: string,
      fieldName?: string,
      captionEN?: string,
      captionDE?: string,
      required?: boolean,
      pos?: number,
      validationRegex?: string,
      validationMessage?: string,
      lookUpGuid?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormDeffinition>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateField(
        formGUID,
        fieldName,
        captionEN,
        captionDE,
        required,
        pos,
        validationRegex,
        validationMessage,
        lookUpGuid,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AdminApiFp(configuration);
  return {
    /**
     *
     * @param {string} formGUID
     * @param {string} [fieldName]
     * @param {string} [captionEN]
     * @param {string} [captionDE]
     * @param {EFieldType} [eFieldType]
     * @param {boolean} [required]
     * @param {number} [pos]
     * @param {string} [validationRegex]
     * @param {string} [validationMessage]
     * @param {string} [lookUpGuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addField(
      formGUID: string,
      fieldName?: string,
      captionEN?: string,
      captionDE?: string,
      eFieldType?: EFieldType,
      required?: boolean,
      pos?: number,
      validationRegex?: string,
      validationMessage?: string,
      lookUpGuid?: string,
      options?: any
    ): AxiosPromise<FormDeffinition> {
      return localVarFp
        .addField(formGUID, fieldName, captionEN, captionDE, eFieldType, required, pos, validationRegex, validationMessage, lookUpGuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [name]
     * @param {string} [nameEN]
     * @param {string} [description]
     * @param {boolean} [mailValidationRequired]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addForm(name?: string, nameEN?: string, description?: string, mailValidationRequired?: boolean, options?: any): AxiosPromise<Form> {
      return localVarFp.addForm(name, nameEN, description, mailValidationRequired, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} formGUID
     * @param {string} fieldName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletField(formGUID: string, fieldName: string, options?: any): AxiosPromise<FormDeffinition> {
      return localVarFp.deletField(formGUID, fieldName, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} formGUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dropForms(formGUID: string, options?: any): AxiosPromise<Array<Form>> {
      return localVarFp.dropForms(formGUID, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forms(options?: any): AxiosPromise<Array<Form>> {
      return localVarFp.forms(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} gUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getForm(gUID: string, options?: any): AxiosPromise<FormDeffinition> {
      return localVarFp.getForm(gUID, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLokUpTableDeffintions(options?: any): AxiosPromise<Array<LokUpTableDeffintion>> {
      return localVarFp.getLokUpTableDeffintions(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} formGUID
     * @param {string} [fieldName]
     * @param {string} [captionEN]
     * @param {string} [captionDE]
     * @param {boolean} [required]
     * @param {number} [pos]
     * @param {string} [validationRegex]
     * @param {string} [validationMessage]
     * @param {string} [lookUpGuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateField(
      formGUID: string,
      fieldName?: string,
      captionEN?: string,
      captionDE?: string,
      required?: boolean,
      pos?: number,
      validationRegex?: string,
      validationMessage?: string,
      lookUpGuid?: string,
      options?: any
    ): AxiosPromise<FormDeffinition> {
      return localVarFp
        .updateField(formGUID, fieldName, captionEN, captionDE, required, pos, validationRegex, validationMessage, lookUpGuid, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
  /**
   *
   * @param {string} formGUID
   * @param {string} [fieldName]
   * @param {string} [captionEN]
   * @param {string} [captionDE]
   * @param {EFieldType} [eFieldType]
   * @param {boolean} [required]
   * @param {number} [pos]
   * @param {string} [validationRegex]
   * @param {string} [validationMessage]
   * @param {string} [lookUpGuid]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public addField(
    formGUID: string,
    fieldName?: string,
    captionEN?: string,
    captionDE?: string,
    eFieldType?: EFieldType,
    required?: boolean,
    pos?: number,
    validationRegex?: string,
    validationMessage?: string,
    lookUpGuid?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .addField(formGUID, fieldName, captionEN, captionDE, eFieldType, required, pos, validationRegex, validationMessage, lookUpGuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [name]
   * @param {string} [nameEN]
   * @param {string} [description]
   * @param {boolean} [mailValidationRequired]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public addForm(name?: string, nameEN?: string, description?: string, mailValidationRequired?: boolean, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .addForm(name, nameEN, description, mailValidationRequired, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} formGUID
   * @param {string} fieldName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public deletField(formGUID: string, fieldName: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .deletField(formGUID, fieldName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} formGUID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public dropForms(formGUID: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .dropForms(formGUID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public forms(options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .forms(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} gUID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getForm(gUID: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .getForm(gUID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getLokUpTableDeffintions(options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .getLokUpTableDeffintions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} formGUID
   * @param {string} [fieldName]
   * @param {string} [captionEN]
   * @param {string} [captionDE]
   * @param {boolean} [required]
   * @param {number} [pos]
   * @param {string} [validationRegex]
   * @param {string} [validationMessage]
   * @param {string} [lookUpGuid]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateField(
    formGUID: string,
    fieldName?: string,
    captionEN?: string,
    captionDE?: string,
    required?: boolean,
    pos?: number,
    validationRegex?: string,
    validationMessage?: string,
    lookUpGuid?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .updateField(formGUID, fieldName, captionEN, captionDE, required, pos, validationRegex, validationMessage, lookUpGuid, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authConfigGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Auth/config`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "GET", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} [code]
     * @param {string} [returnUri]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authGet: async (code?: string, returnUri?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Auth`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "GET", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      if (code !== undefined) {
        localVarQueryParameter["code"] = code;
      }

      if (returnUri !== undefined) {
        localVarQueryParameter["returnUri"] = returnUri;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} [refreshToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authRefreshGet: async (refreshToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Auth/refresh`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "GET", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      if (refreshToken !== undefined) {
        localVarQueryParameter["refresh_token"] = refreshToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authConfigGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringStringTuple>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authConfigGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [code]
     * @param {string} [returnUri]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authGet(
      code?: string,
      returnUri?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authGet(code, returnUri, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [refreshToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authRefreshGet(
      refreshToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authRefreshGet(refreshToken, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authConfigGet(options?: any): AxiosPromise<StringStringTuple> {
      return localVarFp.authConfigGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [code]
     * @param {string} [returnUri]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authGet(code?: string, returnUri?: string, options?: any): AxiosPromise<string> {
      return localVarFp.authGet(code, returnUri, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [refreshToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authRefreshGet(refreshToken?: string, options?: any): AxiosPromise<string> {
      return localVarFp.authRefreshGet(refreshToken, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authConfigGet(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authConfigGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [code]
   * @param {string} [returnUri]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authGet(code?: string, returnUri?: string, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authGet(code, returnUri, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [refreshToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authRefreshGet(refreshToken?: string, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authRefreshGet(refreshToken, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ClientApi - axios parameter creator
 * @export
 */
export const ClientApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} gUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    form: async (gUID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'gUID' is not null or undefined
      assertParamExists("form", "gUID", gUID);
      const localVarPath = `/api/client/Client/forms/{GUID}`.replace(`{${"GUID"}}`, encodeURIComponent(String(gUID)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "GET", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getForms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/client/Client/forms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "GET", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} form
     * @param {FormValues} [formValues]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inertRecord: async (form: string, formValues?: FormValues, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'form' is not null or undefined
      assertParamExists("inertRecord", "form", form);
      const localVarPath = `/api/client/Client/data/InertRecord/{Form}`.replace(`{${"Form"}}`, encodeURIComponent(String(form)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "POST", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(formValues, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lokUpTableDeffintions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/client/Client/data/LokUpTableDeffintions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "GET", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} lookupTable
     * @param {string} [language]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lokUpValues: async (lookupTable: string, language?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'lookupTable' is not null or undefined
      assertParamExists("lokUpValues", "lookupTable", lookupTable);
      const localVarPath = `/api/client/Client/data/LokUpValues/{LookupTable}`.replace(`{${"LookupTable"}}`, encodeURIComponent(String(lookupTable)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "GET", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} form
     * @param {string} record
     * @param {string} field
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFile: async (form: string, record: string, field: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'form' is not null or undefined
      assertParamExists("uploadFile", "form", form);
      // verify required parameter 'record' is not null or undefined
      assertParamExists("uploadFile", "record", record);
      // verify required parameter 'field' is not null or undefined
      assertParamExists("uploadFile", "field", field);
      const localVarPath = `/api/client/Client/data/UploadFile/{Form}/{Record}/{Field}`
        .replace(`{${"Form"}}`, encodeURIComponent(String(form)))
        .replace(`{${"Record"}}`, encodeURIComponent(String(record)))
        .replace(`{${"Field"}}`, encodeURIComponent(String(field)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: "POST", ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication Bearer required
      await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ClientApi - functional programming interface
 * @export
 */
export const ClientApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ClientApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} gUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async form(gUID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormDeffinition>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.form(gUID, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getForms(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Form>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getForms(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} form
     * @param {FormValues} [formValues]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inertRecord(
      form: string,
      formValues?: FormValues,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.inertRecord(form, formValues, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lokUpTableDeffintions(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LokUpTableDeffintion>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.lokUpTableDeffintions(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} lookupTable
     * @param {string} [language]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lokUpValues(
      lookupTable: string,
      language?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LokUpTableValue>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.lokUpValues(lookupTable, language, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} form
     * @param {string} record
     * @param {string} field
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadFile(
      form: string,
      record: string,
      field: string,
      file?: File,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(form, record, field, file, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * ClientApi - factory interface
 * @export
 */
export const ClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ClientApiFp(configuration);
  return {
    /**
     *
     * @param {string} gUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    form(gUID: string, options?: any): AxiosPromise<FormDeffinition> {
      return localVarFp.form(gUID, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getForms(options?: any): AxiosPromise<Array<Form>> {
      return localVarFp.getForms(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} form
     * @param {FormValues} [formValues]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inertRecord(form: string, formValues?: FormValues, options?: any): AxiosPromise<InsertResult> {
      return localVarFp.inertRecord(form, formValues, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lokUpTableDeffintions(options?: any): AxiosPromise<Array<LokUpTableDeffintion>> {
      return localVarFp.lokUpTableDeffintions(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} lookupTable
     * @param {string} [language]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lokUpValues(lookupTable: string, language?: string, options?: any): AxiosPromise<Array<LokUpTableValue>> {
      return localVarFp.lokUpValues(lookupTable, language, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} form
     * @param {string} record
     * @param {string} field
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFile(form: string, record: string, field: string, file?: File, options?: any): AxiosPromise<void> {
      return localVarFp.uploadFile(form, record, field, file, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * ClientApi - object-oriented interface
 * @export
 * @class ClientApi
 * @extends {BaseAPI}
 */
export class ClientApi extends BaseAPI {
  /**
   *
   * @param {string} gUID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public form(gUID: string, options?: AxiosRequestConfig) {
    return ClientApiFp(this.configuration)
      .form(gUID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public getForms(options?: AxiosRequestConfig) {
    return ClientApiFp(this.configuration)
      .getForms(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} form
   * @param {FormValues} [formValues]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public inertRecord(form: string, formValues?: FormValues, options?: AxiosRequestConfig) {
    return ClientApiFp(this.configuration)
      .inertRecord(form, formValues, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public lokUpTableDeffintions(options?: AxiosRequestConfig) {
    return ClientApiFp(this.configuration)
      .lokUpTableDeffintions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} lookupTable
   * @param {string} [language]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public lokUpValues(lookupTable: string, language?: string, options?: AxiosRequestConfig) {
    return ClientApiFp(this.configuration)
      .lokUpValues(lookupTable, language, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} form
   * @param {string} record
   * @param {string} field
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public uploadFile(form: string, record: string, field: string, file?: File, options?: AxiosRequestConfig) {
    return ClientApiFp(this.configuration)
      .uploadFile(form, record, field, file, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
