import {FormField} from "../../../generated/clients";
import {Box, IconButton, Paper, Typography} from "@mui/material";
import { Icons } from "../../../component/icons/Icons";

interface Props {
  field: FormField,
  onDelete: () => void
  onEdit: () => void
  onDrag: (e: DragEvent) => void,
  onDrop: (e: DragEvent) => void,
}

const FormLayoutField = ({field, onDelete, onEdit, onDrag, onDrop}: Props) => {

  // @ts-ignore
  return (<Paper
      draggable={true}
      id={field.name}
      onDragOver={(e: DragEvent) => e.preventDefault()}
      onDragStart={onDrag}
      onDrop={onDrop}
      sx={(theme) => ({width: '100%', background: theme.palette.grey["300"]})}
    >
      <Box
        display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" paddingX={2} paddingY={1}>

        <Box display="flex" flexDirection="column">
          <Typography variant="h6">{field.name}</Typography>
          <Typography variant="subtitle2">{field.fieldType}</Typography>
        </Box>

        <Box display="flex" flexDirection="row">
          <IconButton onClick={onEdit}>{Icons.pencil()}</IconButton>
          <IconButton onClick={onDelete}>{Icons.clear()}</IconButton>
        </Box>
      </Box>
    </Paper>
  )

}

export default FormLayoutField
