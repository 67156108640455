import {FormDeffinition} from "../../../generated/clients";
import {useTranslation} from "react-i18next";
import {Box, Button, Card, CardActions, CardContent, Typography} from "@mui/material";
import FormFieldRenderer from "./FormFieldRenderer";
import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {FormEvent, useState} from "react";
import {ClientApiClient} from "../../../rest/RestHttpClient";
import {FilesContext, UploadFile} from "../data/FilesContext";


interface Props {
  form: FormDeffinition
  onSubmit: (values: unknown, files?: UploadFile[]) => void;
}

const FormRenderer = ({form, onSubmit}: Props): JSX.Element => {
  const {t} = useTranslation();
  const [files, setFiles] = useState<UploadFile[]>([])


  const schema = form.fields.reduce((schemaObject: { [key: string]: any }, ff) => {
    let fieldSchema;
    switch (ff.fieldType) {
      case "Boolean":
        fieldSchema = z.boolean();
        break;
      case "Date":
      case "DateTime":
        fieldSchema = z.date();
        break;
      case "Double":
        fieldSchema = z.number();
        break;
      case "SmallInt":
      case "Integer":
        fieldSchema = z.number().int();
        break;
      case "Text":
        fieldSchema = z.string();
        if (ff.validationRegex) {
          fieldSchema = fieldSchema.regex(new RegExp(ff.validationRegex, 'g'), ff.validationMessage)
        }
        break;
      case "Blob":
        fieldSchema = z.string().optional()
        break
      case "GUID":
      default:
        fieldSchema = z.string();
        break;
    }
    if (!ff.required) {
      fieldSchema = fieldSchema.optional()
    }

    schemaObject[ff.name] = fieldSchema
    return schemaObject
  }, {});

  const {handleSubmit, control, setError} = useForm({
    resolver: zodResolver(z.object(schema)),
    mode: "onTouched"
  })

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault()
    handleSubmit((values) => {
        console.log(values)
        onSubmit(values, files)
      },
      (err) => {
        Object.keys(err).forEach((key) => {
          setError(key, err[key])
        })
      }
    )(event)
  }

  return (
    <Card sx={(theme) => ({height: '100%', maxWidth: 800, background: theme.palette.grey["100"]})}>
      <CardContent>
        <Typography variant="h3">{form.formName}</Typography>
        {form.formDescription && (<Typography variant="subtitle1">{form.formDescription}</Typography>)}

        <form onSubmit={handleFormSubmit} id="rendered-form">
          <Box
            sx={{
              gap: 1,
              mt: 2,
              display: 'flex',
              alignItems: 'start',
              flexDirection: 'column',
            }}>

            <FilesContext.Provider value={{files, setFiles}}>
              {form.fields.map(ff => (
                <FormFieldRenderer key={ff.name} field={ff} control={control}/>
              ))}
            </FilesContext.Provider>

          </Box>

        </form>

      </CardContent>

      <CardActions>
        <Button type="submit" form="rendered-form" variant="outlined">{t('submit')}</Button>
      </CardActions>
    </Card>
  )
}

export default FormRenderer;