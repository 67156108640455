import {createContext, Dispatch, SetStateAction} from "react";
import {Form, FormDeffinition, FormField} from "../../../generated/clients";

type FormContextType = {
  forms: Form[];
  setForms: Dispatch<SetStateAction<Form[]>>;
};

export const FormsContext = createContext<FormContextType>({
  forms: [],
  setForms: () => {},
});
