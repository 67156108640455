import {BrowserRouter, Outlet} from "react-router-dom";
import {Box} from "@mui/material";
import {Header} from "../common/header/Header";
import {RouteConfig} from "./RouteConfig";
import React from "react";

export function MainLayout() {

  return (
    <Box height="100vh" display="flex" flexDirection="column" className="App">
      <Header/>
      <main>
        <Outlet/>
      </main>
    </Box>
  )
}