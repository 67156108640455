import React from "react";
import "../themes/app.scss";
import {BrowserRouter} from "react-router-dom";
import {Header} from "../common/header/Header";
import {Box} from "@mui/material";
import {RouteConfig} from "./RouteConfig";

function App() {
  return (
    <BrowserRouter>
          <RouteConfig />
    </BrowserRouter>
  );
}

export default App;
