import {Controller} from "react-hook-form";
import {Checkbox, FormControlLabel, Switch} from "@mui/material";
import {FieldValues} from "react-hook-form/dist/types";
import {FormComponentProps} from "./FormComponentProps";

interface Props<TFieldValues> extends FormComponentProps<TFieldValues> {
  variant?: "checkbox" | "switch";
  onChange?: () => void;
  open?: boolean;
}

export const FormCheckbox = <TFieldValues extends FieldValues>({
  name,
  onChange,
  control,
  open,
  label,
  className,
  variant = "checkbox"
}: Props<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) =>
        variant === "checkbox" ? (
          <FormControlLabel
            onChange={onChange}
            control={<Checkbox {...field} checked={open ?? field.value} />}
            label={label}
            className={`${className} ${error ? "Mui-error" : ""}`}
          />
        ) : (
          <FormControlLabel
            onChange={onChange}
            control={<Switch {...field} checked={open ?? field.value} />}
            label={label}
            className={`${className} ${error ? "Mui-error" : ""}`}
          />
        )
      }
    />
  );
};
