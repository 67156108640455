import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {HeadCell} from "./EnhancedTableHead";

export interface EndlessTableBodyProps<T extends {id: string}> {
  rows: T[];
  headCells: readonly HeadCell<T>[];
  hideCheckbox: boolean;
  loadNewPage: () => void;
  handleClick: (ev: React.MouseEvent<unknown>, name: string) => void;
  isSelected: (id: string) => boolean;
  rowsUntilLoad: number;
}

export function EndlessTableBody<T extends {id: string}>({
  headCells,
  rows,
  loadNewPage,
  handleClick,
  hideCheckbox,
  isSelected,
  rowsUntilLoad
}: EndlessTableBodyProps<T>) {
  const loadTriggerRow = React.useRef(null);
  const loadAtIndex = rows.length - rowsUntilLoad;

  const callback: IntersectionObserverCallback = (entries, refObserver) => {
    let reload = false;

    entries.forEach((element) => {
      if (element.isIntersecting) {
        reload = true;

        refObserver.unobserve(element.target);
      }
    });

    if (reload && loadNewPage) {
      loadNewPage();
    }
  };

  const observer = new IntersectionObserver(callback);

  React.useEffect(() => {
    if (loadTriggerRow.current) {
      observer.observe(loadTriggerRow.current);
    }
  }, [rows.length]);

  return (
    <TableBody>
      {rows.map((row: T, index: number) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
          <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            ref={loadAtIndex === index ? loadTriggerRow : undefined}
            key={row.id}
            selected={isItemSelected}>
            {hideCheckbox ? undefined : (
              <TableCell padding="checkbox">
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{
                    "aria-labelledby": labelId
                  }}
                />
              </TableCell>
            )}
            {headCells.map((x: HeadCell<T>) => (
              <TableCell align={x.align ?? "left"} key={x.id as string}>
                {row[x.id]}
              </TableCell>
            ))}
          </TableRow>
        );
      })}
    </TableBody>
  );
}
