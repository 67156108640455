import {Control} from "react-hook-form/dist/types";
import {useEffect, useState} from "react";
import {ClientApiClient} from "../../../rest/RestHttpClient";
import {LokUpTableDeffintion, LokUpTableValue} from "../../../generated/clients";
import {FormDropdown, OptionItem} from "../../../component/form/FormDropdown";

interface Props {
  label: string;
  name: string;
  control: Control;
  lookUpGuid: string;
}

const LokupTableField = ({label, control, name, lookUpGuid}: Props) => {
  
  const [definitions, setDefinitions] = useState<OptionItem[]>([])
  
  useEffect(() => {
    ClientApiClient.lokUpValues(lookUpGuid, 'DE').then((res) => {
      let data: Array<LokUpTableValue> = res.data;
      setDefinitions(data.map(d => ({label: d.displayValueDE, value: d.guid})))
    })
  }, [])
  
  return <FormDropdown options={definitions} label={label} name={name} control={control} />
}

export default LokupTableField