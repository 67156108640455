import {useEffect, useState} from "react";
import {FormsHeader} from "./FormsHeader";
import {FormsList} from "./FormsList";
import {Form, FormField} from "../../generated/clients";
import {FormsContext} from "./data/FormsContext";
import {AdminApiClient} from "../../rest/RestHttpClient";

export function Forms() {
  const [forms, setForms] = useState<Form[]>([]);

  const getForms = () => {
    AdminApiClient.forms().then((res) => {
      if(res.data) {
        setForms(res.data)
      }
    })
  }
  useEffect(() => {
    getForms()
  }, []);

  return (
    <FormsContext.Provider
      value={{
        forms: forms,
        setForms: setForms,
      }}>
      <div className="forms">
        <FormsHeader />
        <FormsList items={forms} refresh={getForms}/>
      </div>
    </FormsContext.Provider>
  );
}
