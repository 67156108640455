import {useContext, useState} from "react";
import {FormFieldsContext} from "../data/FormFieldsContext";
import {AdminApiClient} from "../../../rest/RestHttpClient";
import {Box, Button, Card, CardActions, CardContent, Paper, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {FormField} from "../../../generated/clients";
import FormLayoutField from "./FormLayoutField";
import {util} from "prettier";

interface Props {
  formId: string,
  onEditClick: (form?: FormField) => void,
}

const FormLayoutList = ({formId, onEditClick}: Props) => {
  const {t} = useTranslation();

  const {formFields, setFormFields} = useContext(FormFieldsContext)
  const [dragName, setDragName] = useState('')


  const handleDelete = (fieldName: string) => {
    AdminApiClient.deletField(formId, fieldName).then(res => {
      if (res.data) {
        setFormFields(res.data.fields)
      }
    })
  }

  const handleDrag = (e: DragEvent) => {
    // @ts-ignore
    setDragName(e.currentTarget.id)
  }
  const handleDrop = (e: DragEvent) => {
    const dragBox = formFields.find((ff) => ff.name === dragName);
    // @ts-ignore
    const dropBox = formFields.find((ff) => ff.name === e.currentTarget.id);

    if (dragBox && dropBox) {
      const dragBoxPos = dragBox.pos;
      const dropBoxPos = dropBox.pos;

      /*const newFormState = formFields.map((ff) => {
        if (ff.name === dragName) {
          ff.pos = dropBoxPos;
        }
        // @ts-ignore
        if (ff.name === e.currentTarget.id) {
          ff.pos = dragBoxPos;
        }
        return ff;
      });*/

      AdminApiClient.updateField(
        formId,
        dragBox.name,
        dragBox.captionEN,
        dragBox.captionDE,
        dragBox.required,
        0, //Set temp Pos
        dragBox.validationRegex,
        dragBox.validationMessage,
        dragBox.lookUpGuid,
      ).then(res => {
        if (res.data) {
          AdminApiClient.updateField(
            formId,
            dropBox.name,
            dropBox.captionEN,
            dropBox.captionDE,
            dropBox.required,
            dragBoxPos, //switch
            dropBox.validationRegex,
            dropBox.validationMessage,
            dropBox.lookUpGuid,
          ).then(res1 => {
            if (res1.data) {
              AdminApiClient.updateField(
                formId,
                dragBox.name,
                dragBox.captionEN,
                dragBox.captionDE,
                dragBox.required,
                dropBoxPos, //switch
                dragBox.validationRegex,
                dragBox.validationMessage,
                dragBox.lookUpGuid,
              ).then(res2 => {
                if (res2.data) {
                  setFormFields(res2.data.fields)
                }
              })
            }
          })
        }
      })

      //setFormFields(newFormState);
    }
  }

  return (
    <Card sx={(theme) => ({height: '100%', background: theme.palette.grey["100"]})}>
      <CardContent>
        <Typography variant="h3">{t('forms.fieldLayout')}</Typography>

        <Box
          sx={{
            gap: 1,
            mt: 2,
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
          }}
        >
          {formFields.sort((a, b) => a.pos - b.pos).map(ff => (
            <FormLayoutField
              key={ff.name}
              field={ff}
              onDelete={() => handleDelete(ff.name)}
              onEdit={() => onEditClick(ff)}
              onDrag={handleDrag}
              onDrop={handleDrop}
            />))}
        </Box>
      </CardContent>
      <CardActions>
        <Button type="submit" variant="outlined" onClick={() => onEditClick()}>{t('forms.addField')}</Button>
      </CardActions>
    </Card>
  )

}

export default FormLayoutList
