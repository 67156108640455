import {FormField} from "../../../generated/clients";
import {createContext, Dispatch, SetStateAction} from "react";

type FormFieldsContextType = {
  formFields: FormField[];
  setFormFields: Dispatch<SetStateAction<FormField[]>>;
}

export const FormFieldsContext = createContext<FormFieldsContextType>({
  formFields: [],
  setFormFields: () => {}
})
