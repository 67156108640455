import {useContext} from "react";
import {FormFieldsContext} from "../data/FormFieldsContext";
import FormRenderer from "../renderer/FormRenderer";


const EditFormLayoutRenderer = ({name}: { name: string }): JSX.Element => {
  const {formFields} = useContext(FormFieldsContext);

  return (
    <FormRenderer form={{
      formName: name,
      formDescription: "",
      fields: formFields,
      mailValidationRequired: false
    }}
      onSubmit={() => {}}
    ></FormRenderer>
  )
}

export default EditFormLayoutRenderer;
