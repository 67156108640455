import {useEffect, useState} from "react";
import {Form, FormField} from "../../generated/clients";
import {ClientApiClient} from "../../rest/RestHttpClient";
import {Box, Button} from "@mui/material";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ViewFormsList} from "./ViewFormsList";

export function ViewForms() {
  const {t} = useTranslation();

  const [forms, setForms] = useState<Form[]>([]);
  
  const getForms = () => {
    ClientApiClient.getForms().then((res) => {
      if(res.data) {
        setForms(res.data)
      }
    })
  }
  
  useEffect(() => {
    getForms()
  }, [])
  
  
  return (
    <div>
      <Box className="view-header">
        <h2>{t("forms.viewForms")}</h2>
        <Box className="button-container">
          
        </Box>
      </Box>
      <ViewFormsList items={forms} refresh={getForms} />
    </div>
  )
}