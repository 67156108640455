import {Controller} from "react-hook-form";
import {TextField, TextFieldProps} from "@mui/material";
import {FormComponentProps} from "./FormComponentProps";
import {FieldValues} from "react-hook-form/dist/types";
import {Icons} from "../icons/Icons";

interface Props<TFieldValues> extends FormComponentProps<TFieldValues> {
  withIcon?: boolean;
  shrink?: boolean;
}

export const FormTextField = <TFieldValues extends FieldValues>({
                                                                  name,
                                                                  control,
                                                                  label,
                                                                  placeholder,
                                                                  className,
                                                                  withIcon,
                                                                  type,
                                                                  shrink,
                                                                  ...others
                                                                }: Props<TFieldValues> & TextFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => (
        <TextField
          label={label}
          error={!!error}
          placeholder={placeholder}
          className={className}
          fullWidth={true}
          variant="standard"
          type={type}
          InputProps={{
            endAdornment: withIcon && Icons.search(),
          }}
          helperText={error ? error.message : ''}
          InputLabelProps={{shrink: shrink}}
          {...field}
          value={field.value || ""}
          {...others}
        />
      )}
    />
  );
};
