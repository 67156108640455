import {Form} from "../../generated/clients";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {HeadCell} from "../../common/table/EnhancedTableHead";
import * as React from "react";
import {Box, IconButton} from "@mui/material";
import {EnhancedTable} from "../../common/table/EnhancedTable";
import {Icons} from "../../component/icons/Icons";
import DataArrayIcon from "@mui/icons-material/DataArray";

interface FormRow {
  id: string;
  name?: JSX.Element;
  nameEn?: JSX.Element;
  icon?: JSX.Element;
}

interface Props {
  items: Form[];
  refresh: () => void;
}

export function ViewFormsList({items, refresh}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const headCells: readonly HeadCell<FormRow>[] = [
    {
      id: "name",
      disablePadding: false,
      label: t("forms.list.name")
    },
    {
      id: "nameEn",
      disablePadding: false,
      label: t("forms.list.nameEn")
    },
    {
      id: "icon",
      disablePadding: true,
      label: ""
    }
  ];

  const onPlay = (id: string) => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    navigate(id);
  };

  const onPlayEmbedded = (id: string) => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    navigate(`/embedded/${id}`);
  };

  function mapForm(form: Form): FormRow {
    return {
      id: form.guid,
      name: (
        <Link style={{color: "inherit", textDecoration: "inherit"}} to={form.guid}>
          <p>{form.name}</p>
        </Link>
      ),
      nameEn: (
        <Link style={{color: "inherit", textDecoration: "inherit"}} to={form.guid}>
          <p>{form.nameEn}</p>
        </Link>
      ),
      icon: (
        <Box display="flex" flexDirection="row">
          {/*<IconButton
          aria-label="play" onClick={onPlay(form.guid)}>{Icons.chevronRight()}</IconButton>
        <IconButton
          aria-label="play-embedded" onClick={onPlayEmbedded(form.guid)}><DataArrayIcon /></IconButton>*/}
        </Box>
      )
    };
  }

  const rows: FormRow[] = items.map(mapForm);

  return (
    <Box className="customer-list">
      {<EnhancedTable rows={rows} headCells={headCells} hideCheckbox={true} onRowClick={(_, id) => onPlay(id)} variant="default"></EnhancedTable>}
    </Box>
  );
}
