import {Box} from "@mui/system";
import {FormEvent, useEffect, useState} from "react";
import {z} from "zod";
import {useParams} from "react-router";
import {Button, Grid} from "@mui/material";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FormFieldsContext} from "../data/FormFieldsContext";
import {FormField} from "../../../generated/clients";
import {AdminApiClient} from "../../../rest/RestHttpClient";
import FormLayoutList from "./FormLayoutList";
import FormLayoutEditor from "./FormLayoutEditor";
import {createFormSchema, editFieldSchema} from "../data/FormFromValues";
import {FormTextField} from "../../../component/form/FormTextField";
import {FormCheckbox} from "../../../component/form/FormCheckbox";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import EditFormLayoutRenderer from "./EditFormLayoutRenderer";

export type FieldEditValues = z.infer<typeof editFieldSchema>
export type FormValues = z.infer<typeof createFormSchema>

export function FormEdit() {
  const {t} = useTranslation();
  const [formFields, setFormFields] = useState<FormField[]>([]);
  const [name, setName] = useState('')
  const [editField, setEditField] = useState<FormField>();

  const {control, handleSubmit, setValue} = useForm<FormValues>({
    resolver: zodResolver(createFormSchema),
  })

  const {id} = useParams();

  useEffect(() => {
    if (id) {
      AdminApiClient.getForm(id).then(res => {
        if (res.data) {
          setFormFields(res.data.fields)
          setName(res.data.formName)
          setValue('name', res.data.formName)
        }
      })
    }
  }, [id]);

  const handleEditClick = (ff?: FormField) => {
    setEditField(ff )
  }

  const onHandleSubmit = (event: FormEvent) => {
    event.preventDefault();
    handleSubmit((values) => {
      console.log(values)
    }, console.error)(event)
  }

  return (
    <Box>
      <Box className="view-header">
        <h2>{t("forms.editForm")}</h2>
        <Box className="button-container">
          <Button component={Link} to="/" color="secondary" variant="outlined">
            {t("cancel")}
          </Button>
        </Box>
      </Box>

      <form id="edit-form-form" onSubmit={onHandleSubmit}>
        <Box display="flex" flexDirection="column" alignItems="start" gap={2} sx={{maxWidth: 400}}>
          <FormTextField control={control} label={t('forms.list.name')} name="name"/>
          <FormTextField control={control} label={t('forms.list.nameEn')} name="nameEn"/>
          <FormTextField control={control} label={t('forms.list.description')} name="description"/>
          <FormCheckbox control={control} label={t('forms.list.mailValidationRequired')} name="mailValidationRequired"/>

          <Button type="submit" variant="contained" form="edit-form-form">
            {t("save")}
          </Button>
        </Box>
      </form>

      {id && (
        <FormFieldsContext.Provider
          value={{
            formFields: formFields,
            setFormFields: setFormFields
          }}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={4}>
              <FormLayoutList formId={id} onEditClick={handleEditClick}/>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormLayoutEditor formId={id} editField={editField} setEditField={setEditField}/>
            </Grid>
            <Grid item xs={12} md={4}>
              <EditFormLayoutRenderer name={name}/>
            </Grid>
          </Grid>

        </FormFieldsContext.Provider>
      )}
    </Box>
  );
}
