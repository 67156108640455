import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import {HeadCell, EnhancedTableHead} from "./EnhancedTableHead";
import {EnhancedTableToolbarButton} from "./EnhancedTableToolbar";
import {Order} from "./TableComparator";
import {EndlessTableBody} from "./EndlessTableBody";
import {PaginatedTableBody} from "./PaginatedTableBody";

export interface EnhancedTableProps<T> {
  rows: T[];
  headCells: readonly HeadCell<T>[];
  hideCheckbox: boolean;
  toolbarButtons?: EnhancedTableToolbarButton[];
  variant?: "pagination" | "endless" | "default";
  enableSelection?: boolean;
  onRowClick?: (ev: React.MouseEvent<unknown>, name: string) => void;
  loadNewPage?: () => void;
  rowsUntilLoad?: number;
  minWidth?: number;
}

export function EnhancedTable<T extends {id: string}>({
  rows,
  headCells,
  hideCheckbox,
  enableSelection,
  variant,
  onRowClick,
  loadNewPage,
  rowsUntilLoad,
  minWidth
}: EnhancedTableProps<T>) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof T>("id");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const withPagination = variant === "pagination";
  const withEndless = loadNewPage && variant === "endless";

  const handleRequestSort = (_: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (enableSelection) {
      if (event.target.checked) {
        const newSelecteds = rows.map((row) => row.id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    }
  };

  const handleClick = (ev: React.MouseEvent<unknown>, name: string) => {
    if (onRowClick) {
      onRowClick(ev, name);
    }

    if (enableSelection) {
      const selectedIndex = selected.indexOf(name);
      let newSelected: readonly string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      setSelected(newSelected);
    }
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box className="enhanced-table">
      <TableContainer>
        <Table sx={{minWidth: minWidth ?? 750}} aria-labelledby="tableTitle" size={"medium"}>
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
            hideCheckbox={hideCheckbox}
          />
          {withEndless ? (
            <EndlessTableBody
              rows={rows}
              headCells={headCells}
              hideCheckbox={hideCheckbox}
              loadNewPage={loadNewPage}
              handleClick={handleClick}
              isSelected={isSelected}
              rowsUntilLoad={rowsUntilLoad ?? 5}
            />
          ) : (
            <PaginatedTableBody
              rows={rows}
              headCells={headCells}
              hideCheckbox={hideCheckbox}
              handleClick={handleClick}
              isSelected={isSelected}
              order={"asc"}
              orderBy={orderBy}
              withPagination={withPagination}
              rowsPerPage={rowsPerPage}
              emptyRows={emptyRows}
              page={page}
            />
          )}
        </Table>
      </TableContainer>
      {withPagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}
