import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import React from "react";
import {Forms} from "./forms/Forms";
import {NotFound} from "./not-found/NotFound";
import {FormEdit} from "./forms/edit/FormEdit";
import {NewForm} from "./forms/new/NewForm";
import {FormView} from "./viewForms/FormView";
import {ViewForms} from "./viewForms/ViewForms";
import {MainLayout} from "./MainLayout";
import {EmbeddableFormView} from "./viewForms/EmbeddableFormView";
import Login from "./Login";
import {ShowAdminPage} from "../rest/RestHttpClient";

export function RouteConfig() {
  return (
    <Routes>
      <Route path="signin-oidc" element={<Login></Login>}></Route>
      {ShowAdminPage ? (
        <Route path="forms" element={<MainLayout />}>
          <Route index element={<Forms />} />
          <Route path=":id" element={<FormEdit />} />
          <Route path="new" element={<NewForm />} />
        </Route>
      ) : (
        <></>
      )}
      <Route path="view-forms" element={<MainLayout />}>
        <Route index element={<ViewForms />} />
        <Route path=":id" element={<FormView />} />
      </Route>
      <Route path="embedded">
        <Route path=":id" element={<EmbeddableFormView />} />
      </Route>
      <Route path="/" element={<Navigate to={ShowAdminPage ? "/forms" : "/view-forms"} replace={true} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
