import {useContext} from "react";
import {FilesContext} from "../data/FilesContext";
import {UploadInput} from "../../../component/UploadInput";

interface Props {
  label: string;
  name: string;
}

const UploadField = ({label, name}: Props) => {
  const {files, setFiles} = useContext(FilesContext);

  const file = files.find((f) => f.field === name)?.file;
  const handleFileChange = (file?: File) => {
    if (file) {
      setFiles([
        ...files.filter((f) => f.field !== name),
        {
          file: file,
          field: name
        }
      ]);
    } else {
      setFiles([]);
    }
  };

  return <UploadInput file={file} label={label} onFileChange={handleFileChange} />;
};

export default UploadField;
