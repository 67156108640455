import {Checkbox, TableBody, TableCell, TableRow} from "@mui/material";
import {HeadCell} from "./EnhancedTableHead";
import {getComparator, Order} from "./TableComparator";
import {stableSort} from "./TableSorter";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export interface PaginatedTableBodyProps<T extends { id: string }> {
  rows: T[];
  headCells: readonly HeadCell<T>[];
  hideCheckbox: boolean;
  handleClick: (ev: React.MouseEvent<unknown>, name: string) => void;
  isSelected: (id: string) => boolean;
  order: Order;
  orderBy: keyof T;
  withPagination: boolean;
  rowsPerPage: number;
  emptyRows: number;
  page: number;
}

export function PaginatedTableBody<T extends { id: string }>({
                                                               headCells,
                                                               rows,
                                                               hideCheckbox,
                                                               handleClick,
                                                               isSelected,
                                                               order,
                                                               orderBy,
                                                               withPagination,
                                                               rowsPerPage,
                                                               emptyRows,
                                                               page
                                                             }: PaginatedTableBodyProps<T>) {
  return (
    <TableBody>
      {stableSort<T>(rows, getComparator<keyof T>(order, orderBy))
        .slice(withPagination ? page * rowsPerPage : undefined, withPagination ? page * rowsPerPage + rowsPerPage : undefined)
        .map((row: T, index: number) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              onClick={(event) => handleClick(event, row.id)}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              selected={isItemSelected}>
              {hideCheckbox ? undefined : (
                <TableCell padding="checkbox">
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon/>}
                    checkedIcon={<CheckCircleIcon/>}
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      "aria-labelledby": labelId
                    }}
                  />
                </TableCell>
              )}
              {headCells.map((x: HeadCell<T>) => (
                <TableCell align={x.align ?? "left"} key={x.id as string}>
                  {row[x.id]}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: 53 * emptyRows
          }}>
          <TableCell colSpan={6}/>
        </TableRow>
      )}
    </TableBody>
  );
}
