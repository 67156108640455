import {Box, Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export function NewFormHeader() {
  const {t} = useTranslation();

  return (
    <Box className="view-header">
      <h2>{t("forms.newForm")}</h2>
      <Box className="button-container">
        <Button component={Link} to="/" color="secondary" variant="outlined">
          {t("cancel")}
        </Button>
      </Box>
    </Box>
  );
}
