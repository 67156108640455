import {useSearchParams, Navigate, useNavigate} from "react-router-dom";
import {setToken} from "../rest/RestHttpClient";

function Login() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  var c = String(searchParams.get("code"));
  if (c)
    setToken(String(c)).then((x) => {
      var path = localStorage.getItem("path");
      if (path) {
        localStorage.removeItem("path");
        navigate(path);
      } else {
        navigate("/");
      }
    });
  return <div></div>;
}

export default Login;
