import {FormField} from "../../../generated/clients";
import {Box, Paper, Typography} from "@mui/material";
import {Control} from "react-hook-form/dist/types";
import {FormCheckbox} from "../../../component/form/FormCheckbox";
import {FormDatePicker} from "../../../component/form/FormDatePicker";
import {FormNumberInput} from "../../../component/form/FormNumberInput";
import {FormTextField} from "../../../component/form/FormTextField";
import {useTranslation} from "react-i18next";
import LokupTableField from "./LokupTableField";
import UploadField from "./UploadField";

interface Props {
  field: FormField,
  control: Control
}

const FormFieldRenderer = ({field, control}: Props) => {
  const {t} = useTranslation();

  const renderField = () => {
    switch (field.fieldType) {
      case "Boolean":
        return (
          <FormCheckbox label={field.captionDE} name={field.name} control={control}/>
        )
      case "Date":
      case "DateTime":
        return <FormDatePicker label={field.captionDE} name={field.name} control={control}/>
      case "Double":
      case "SmallInt":
      case "Integer":
        return <FormNumberInput label={field.captionDE} name={field.name} control={control}/>
      case "GUID":
        return <LokupTableField lookUpGuid={field.lookUpGuid} label={field.captionDE} name={field.name} control={control} />
      case "Blob":
        return <UploadField label={field.captionDE} name={field.name} />
      case "Text":
      default:
        return <FormTextField label={field.captionDE} name={field.name} control={control}/>
    }
  }

  return (
    <Paper sx={(theme) => ({width: '100%', background: theme.palette.grey["300"]})}>
      <Box sx={{display: 'flex', flexDirection: 'column', paddingX: 2, paddingY: 1}}>
        {renderField()}
        {field.required || field.fieldType === 'Blob' ? <span/> : <Typography sx={{fontSize: 12}}>{t('optional')}</Typography>}
      </Box>
    </Paper>
  )

}

export default FormFieldRenderer
