import {Controller} from "react-hook-form";
import {FormComponentProps} from "./FormComponentProps";
import {MenuItem, TextField, TextFieldProps} from "@mui/material";
import {FieldValues} from "react-hook-form/dist/types";

export interface OptionItem {
  label: string;
  value: string | number;
}

interface FormDropdownProps<TFieldValues> extends FormComponentProps<TFieldValues> {
  options: OptionItem[];
  emptyText?: string;
  isRequired?: boolean;
}

export const FormDropdown = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
  options,
  placeholder,
  shrink,
  emptyText,
  isRequired,
  ...others
}: FormDropdownProps<TFieldValues> & TextFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => (
        <TextField
          label={label}
          error={!!error}
          placeholder={label}
          required={isRequired}
          {...field}
          value={field.value || ""}
          fullWidth={true}
          select={true}
          InputLabelProps={{shrink}}
          variant="standard"
          {...others}>
          {emptyText && (
            <MenuItem value="">
              <em>{emptyText}</em>
            </MenuItem>
          )}
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}></Controller>
  );
};
