import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router";
import {FormDeffinition, FormValue, FormValues} from "../../generated/clients";
import {useEffect, useState} from "react";
import {ClientApiClient} from "../../rest/RestHttpClient";
import FormRenderer from "../forms/renderer/FormRenderer";
import {Box, Button} from "@mui/material";
import {Link} from "react-router-dom";
import {pushNotificationHandler} from "../../common/PushNotification";
import {UploadFile} from "../forms/data/FilesContext";

export function FormView() {
  const {t} = useTranslation();
  const {id} = useParams();
  const navigate = useNavigate();

  const [formDefinition, setFormDefinition] = useState<FormDeffinition>();

  useEffect(() => {
    if (id) {
      ClientApiClient.form(id).then((res) => {
        setFormDefinition(res.data);
      });
    }
  }, [id]);

  const handleSubmit = async (values: any, files?: UploadFile[]) => {
    const formValues: FormValue[] = Object.keys(values)
      .filter((x) => values[x] != undefined)
      .map((key) => ({
        fieldName: key,
        value: String(values[key])
      }));

    if (id) {
      try {
        const {data} = await ClientApiClient.inertRecord(id, {values: formValues});
        if (files && data.id) {
          Promise.all([...files.map((f) => ClientApiClient.uploadFile(id, data.id, f.field, f.file))]).then(() => {
            pushNotificationHandler.publish(data.messageDE ?? t("success"), "success");
            navigate(`/view-forms`);
          });
        } else {
          pushNotificationHandler.publish(data.messageDE ?? t("success"), "success");
          navigate(`/view-forms`);
        }
      } catch (e) {
        console.error(e);
        pushNotificationHandler.publish(t("error"), "error");
      }
    }
  };

  return (
    <Box>
      <Box className="view-header">
        <h2>{t("forms.viewForm")}</h2>
        <Box className="button-container">
          <Button component={Link} to="/" color="secondary" variant="outlined">
            {t("cancel")}
          </Button>
        </Box>
      </Box>
      <Box sx={{display: "flex", flexDirection: "column"}}>{formDefinition && <FormRenderer form={formDefinition} onSubmit={handleSubmit} />}</Box>
    </Box>
  );
}
