import {createContext, Dispatch, SetStateAction} from "react";


export interface UploadFile {
  file: File,
  field: string,
}

interface FilesContextType {
  files: UploadFile[],
  setFiles: Dispatch<SetStateAction<UploadFile[]>>
} 

export const FilesContext = createContext<FilesContextType>({
  files: [],
  setFiles: () => {}
})