// Generated by ts-to-zod
import {z} from "zod";
import {EFieldType} from "../../../generated/clients";

const optionalDate = z
  .date()
  .optional()
  .nullable()
  .transform((value) => (value instanceof Date ? value.toISOString() : null));
const radioButton = z.union([z.literal("true"), z.literal("false")]).transform((value) => value === "true");

const integerGreaterZero = z.union([
  z.undefined(),
  z.null(),
  z
    .string()
    .transform(Number)
    .refine((n) => n >= 0 && Number.isInteger(n))
]);

const numberGreaterZero = z.number().nonnegative().optional().nullable();

const checkBoxMustBeTrue = z.boolean().refine((val) => val === true);

export const createFormSchema = z.object({
  name: z.string(),
  nameEn: z.string(),
  description: z.string(),
  mailValidationRequired: z.boolean()
});

export const editFieldSchema = z.object({
  fieldName: z.string(),
  captionEN: z.string().optional(),
  captionDE: z.string().optional(),
  fieldType: z.string(),
  required: z.boolean(),
  pos: z.number(),
  ValidationRegex: z.string().optional(),
  ValidationMessage: z.string().optional(),
  LookUpGuid: z.string().optional(),
})
