import {NewFormHeader} from "./NewFormHeader";
import {useForm} from "react-hook-form";
import {z} from "zod";
import {createFormSchema} from "../data/FormFromValues";
import {zodResolver} from "@hookform/resolvers/zod";
import {useNavigate} from "react-router";
import {FormEvent} from "react";
import {AdminApiClient} from "../../../rest/RestHttpClient";
import {FormTextField} from "../../../component/form/FormTextField";
import {useTranslation} from "react-i18next";
import {FormCheckbox} from "../../../component/form/FormCheckbox";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

export type FormValues = z.infer<typeof createFormSchema>

export function NewForm() {
  const {t} = useTranslation()

  const {control, handleSubmit, formState} = useForm<FormValues>({
    resolver: zodResolver(createFormSchema),
    defaultValues: {
      mailValidationRequired: false,
    }
  })

  const navigate = useNavigate();

  const onHandleSubmit = (event: FormEvent) => {
    event.preventDefault();
    console.log(event)
    handleSubmit(
      (values) => {
        AdminApiClient.addForm(values.name, values.nameEn, values.description, values.mailValidationRequired)
          .then((res) => {
            if (res.data) {
              navigate(`/forms/${res.data.guid}`)
            }
          }).catch((err) => console.error(err))
      },
      (err) => {
        console.error(err)
      }
    )(event)
  }

  return (
    <div className="new-form">

      <form id="new-form-form" onSubmit={onHandleSubmit}>
        <NewFormHeader/>
        <Box display="flex" flexDirection="column" alignItems="start" gap={2}>
          <FormTextField control={control} label={t('forms.list.name')} name="name"/>
          <FormTextField control={control} label={t('forms.list.nameEn')} name="nameEn"/>
          <FormTextField control={control} label={t('forms.list.description')} name="description"/>
          <FormCheckbox control={control} label={t('forms.list.mailValidationRequired')} name="mailValidationRequired"/>

          <Button type="submit" variant="contained" form="new-form-form">
            {t("save")}
          </Button>
        </Box>
      </form>

    </div>
  );
}
