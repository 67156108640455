import {useForm, useWatch} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod/dist/zod";
import {editFieldSchema} from "../data/FormFromValues";
import {EFieldType, Form, FormField} from "../../../generated/clients";
import {FieldEditValues} from "./FormEdit";
import {Dispatch, FormEvent, SetStateAction, useContext, useEffect, useState} from "react";
import {FormFieldsContext} from "../data/FormFieldsContext";
import {Box, Button, Card, CardActions, CardContent, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {FormTextField} from "../../../component/form/FormTextField";
import {FormCheckbox} from "../../../component/form/FormCheckbox";
import {FormDropdown, OptionItem} from "../../../component/form/FormDropdown";
import {AdminApiClient} from "../../../rest/RestHttpClient";
import {t} from "i18next";
import {Edit} from "@mui/icons-material";


interface Props {
  formId: string,
  editField?: FormField,
  setEditField: (form?: FormField) => void,
}

const supportedFieldTypes = {
  Guid: 'GUID',
  Text: 'Text',
  Boolean: 'Boolean',
  DateTime: 'DateTime',
  Integer: 'Integer',
  Double: 'Double',
  Date: 'Date',
  Time: 'Time',
  Blob: "Blob"
}

const typeOptions: OptionItem[] = Object.values(supportedFieldTypes)
  .map(eft => ({label: t(`fieldTypes.${eft}`), value: eft}))

const FormLayoutEditor = ({formId, editField, setEditField}: Props) => {
  const {t} = useTranslation();
  const {formFields, setFormFields} = useContext(FormFieldsContext)
  const [tableDefinitions, setTableDefinitions] = useState<OptionItem[]>([]);

  const {control, handleSubmit, reset, setValue} = useForm<FieldEditValues>({
    resolver: zodResolver(editFieldSchema),
    defaultValues: {
      fieldName: '',
      captionEN: undefined,
      captionDE: undefined,
      fieldType: EFieldType.Text,
      required: false,
      pos: formFields.length,
      ValidationRegex: undefined,
      ValidationMessage: undefined,
      LookUpGuid: undefined,
    }
  })

  useEffect(() => {
    AdminApiClient.getLokUpTableDeffintions().then((res) => {
      setTableDefinitions(res.data.map(ltd => ({value: ltd.guid, label: ltd.lokUpTableName})))
    })
  }, [])

  useEffect(() => {
    if (editField) {
      setValue('fieldName', editField.name)
      setValue('captionEN', editField.captionEN)
      setValue('captionDE', editField.captionDE)
      setValue('fieldType', editField.fieldType)
      setValue('required', editField.required)
      setValue('pos', editField.pos)
      setValue('ValidationRegex', editField.validationRegex)
      setValue('ValidationMessage', editField.validationMessage)
      setValue('LookUpGuid', editField.lookUpGuid)
    } else {
      reset()
    }
    // eslint-disable-next-line
  }, [editField]);


  const onHandleSubmit = (event: FormEvent) => {
    event.preventDefault()

    handleSubmit(
      (values) => {
        const isNew = formFields.findIndex(v => v.name === values.fieldName) === -1;
        if (isNew) {
          AdminApiClient.addField(
            formId,
            values.fieldName,
            values.captionEN,
            values.captionDE,
            values.fieldType as EFieldType,
            values.fieldType !== 'Blob' ? values.required : false,
            formFields.length + Math.round(Math.random() * 1000),
            values.ValidationRegex,
            values.ValidationMessage,
            values.LookUpGuid,
          ).then(res => {
            if (res.data) {
              setFormFields(res.data.fields)
            }
          })
        } else {
          AdminApiClient.updateField(
            formId,
            values.fieldName,
            values.captionEN,
            values.captionDE,
            values.required,
            values.pos,
            values.ValidationRegex,
            values.ValidationMessage,
            values.LookUpGuid,
          ).then(res => {
            if (res.data) {
              setFormFields(res.data.fields)
            }
          })
        }

        reset()
        setEditField(undefined);
      },
      console.error
    )(event)
  }

  const fieldName = useWatch({control, name: 'fieldName'})
  const fieldType = useWatch({control, name: 'fieldType'})
  return (
    <form onSubmit={onHandleSubmit}>
      <Card>
        <CardContent>
          <Typography variant="h3">
            {t('forms.fieldEditor')}
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            gap={2}
          >

            <FormTextField disabled={Boolean(editField)} control={control} label={t('forms.formField.name')} name="fieldName"/>
            <FormTextField control={control} label={t('forms.formField.captionEN')} name="captionEN"/>
            <FormTextField control={control} label={t('forms.formField.captionDE')} name="captionDE"/>

            {(formFields.findIndex(v => v.name === fieldName) === -1) && (
              <FormDropdown
                control={control} label={t('forms.formField.fieldType')} name="fieldType" options={typeOptions}
              />
            )}

            {fieldType !== "Blob" && (
              <FormCheckbox control={control} label={t('forms.formField.required')} name="required"/>
            )}

            {
              fieldType === "Text" && (
                <>
                  <FormTextField control={control} label={t('forms.formField.validationRegex')} name="ValidationRegex"/>
                  <FormTextField control={control} label={t('forms.formField.validationMessage')}
                                 name="ValidationMessage"/>
                </>
              )
            }


            {fieldType === "GUID" && (
              <FormDropdown control={control} options={tableDefinitions} label={t('forms.formField.lookUpGuid')}
                            name="LookUpGuid"/>
            )}

          </Box>

        </CardContent>
        <CardActions>
          <Button type="submit" variant="outlined">{t('save')}</Button>
        </CardActions>
      </Card>
    </form>
  )
}

export default FormLayoutEditor;
