import React from "react";
import SearchIcon from "./search.svg";
import CollapsiblePanelIcon from "./collapsible-panel.svg";
import ChevronDownIcon from "./chevron-down.svg";
import ChevronRightIcon from "./chevron-right.svg";
import Pencil from "./pencil.svg";
import Delete from "./delete.svg";
import Calendar from "./calendar.svg";
import Clear from "./clear.svg";
import Upload from "./upload.svg";
import UploadAlt from "./uploadAlt.svg";

function renderIcon(iconItself: any, classes: string[] = []): JSX.Element {
  return <img className={[...classes].join(" ")} src={iconItself} alt="Icon" />;
}

export const Icons = {
  search: (...classNames: string[]): JSX.Element => renderIcon(SearchIcon, classNames),
  collapsiblePanel: (...classNames: string[]): JSX.Element => renderIcon(CollapsiblePanelIcon, classNames),
  chevronDown: (...classNames: string[]): JSX.Element => renderIcon(ChevronDownIcon, classNames),
  chevronRight: (...classNames: string[]): JSX.Element => renderIcon(ChevronRightIcon, classNames),
  pencil: (...classNames: string[]): JSX.Element => renderIcon(Pencil, classNames),
  delete: (...classNames: string[]): JSX.Element => renderIcon(Delete, classNames),
  calendar: (...classNames: string[]): JSX.Element => renderIcon(Calendar, classNames),
  clear: (...classNames: string[]): JSX.Element => renderIcon(Clear, classNames),
  upload: (...classNames: string[]): JSX.Element => renderIcon(Upload, classNames),
  uploadAlt: (...classNames: string[]): JSX.Element => renderIcon(UploadAlt, classNames)
};
