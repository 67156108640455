import {Icons} from "./icons/Icons";
import Button from "@mui/material/Button";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Grid, IconButton, LinearProgress} from "@mui/material";
import {Clear} from "@mui/icons-material";
import i18n from "../common/i18n/i18n";

interface Props {
  accept: string;
  file?: File;
  onFileChange: (file?: File) => void;
  small?: boolean;
  label: string;
}

export function UploadInput({accept, file, onFileChange, small, label}: Props) {
  const {t} = useTranslation();

  const uploadInput = useRef(null);

  const [dragging, setDragging] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);

  useEffect(() => {
    window.addEventListener("dragover", overrideEventDefaults);
    window.addEventListener("drop", overrideEventDefaults);
    return () => {
      window.removeEventListener("dragover", overrideEventDefaults);
      window.removeEventListener("drop", overrideEventDefaults);
    };
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      onFileChange(event.target.files[0]);
    }
  };

  const onSelectFileClick = () => {
    // @ts-ignore
    uploadInput && uploadInput.current && uploadInput.current.click();
  };

  const onCancel = () => {
    //if (uploadInput?.current)
    //uploadInput.current.value = undefined;
    onFileChange(undefined);
    if (uploadInput?.current) {
      // @ts-ignore
      uploadInput.current.value = null;
    }
  };

  const overrideEventDefaults = (event: Event | React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const dragEnterListener = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);

    setDragCounter(dragCounter + 1);
    if (event.dataTransfer.items && event.dataTransfer.items[0]) {
      setDragging(true);
    }
  };

  const dragLeaveListener = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);
    if (dragCounter <= 1) {
      setDragging(false);
    }
    setDragCounter(dragCounter - 1);
  };

  const dropListener = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);
    setDragCounter(0);
    setDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      onFileChange(event.dataTransfer.files[0]);
    }
  };

  return (
    <>
      {small ? (
        <div className="upload-container" style={{height: "40px", border: "none", margin: 0}} onDrop={dropListener}>
          {!file ? (
            <>
              <Button color="secondary" variant="outlined" onClick={onSelectFileClick}>
                {label}
              </Button>
            </>
          ) : (
            <Grid container sx={{alignItems: "center"}}>
              <Grid item xs={8}>
                <LinearProgress classes={{root: "process"}} />
              </Grid>
              <Grid item xs={4}>
                <IconButton onClick={onCancel} color="primary">
                  {<Clear />}
                </IconButton>
              </Grid>
            </Grid>
          )}
        </div>
      ) : (
        <div
          className="upload-container"
          onDrag={overrideEventDefaults}
          onDragStart={overrideEventDefaults}
          onDragEnd={overrideEventDefaults}
          onDragOver={overrideEventDefaults}
          onDragEnter={dragEnterListener}
          onDragLeave={dragLeaveListener}
          onDrop={dropListener}>
          {dragging ? (
            <div className="dragging-overlay">
              {Icons.uploadAlt()}
              <div>{t("upload.dragging")}</div>
            </div>
          ) : (
            <div className="content">
              {file ? (
                <>
                  <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>{Icons.upload()}</div>
                  <div style={{textAlign: "center", fontWeight: "bold", fontSize: "18px"}}>{label}</div>
                  <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div className="content" style={{textAlign: "center"}}>
                      {file.name}
                    </div>
                    <div className="content" style={{marginLeft: "-25px"}}>
                      <IconButton onClick={onCancel} color="primary" size="small">
                        {Icons.delete()}
                      </IconButton>
                    </div>
                  </div>
                  <div className="subtitle">{t("upload.uploading", {filename: file.name})}</div>
                </>
              ) : (
                <>
                  <div className="title">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>{Icons.upload()}</div>
                    <div style={{textAlign: "center"}}>{label}</div>
                  </div>
                  <div className="subtitle">{t("upload.subtitle")}</div>
                  <div className="info">{t("upload.info")}</div>
                  <Button color="secondary" variant="outlined" onClick={onSelectFileClick}>
                    {label}
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      )}
      <input ref={uploadInput} type="file" accept={accept} className="file-uploader__input" onChange={handleFileChange} />
    </>
  );
}

UploadInput.defaultProps = {
  accept: "*",
  label: i18n.t("upload.title")
};
