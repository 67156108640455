import React from "react";
import {Controller} from "react-hook-form";
import {InputBaseComponentProps, TextField, TextFieldProps} from "@mui/material";
import {FormComponentProps} from "./FormComponentProps";
import {FieldValues} from "react-hook-form/dist/types";
import NumberFormat, {NumberFormatProps} from "react-number-format";

interface Props<TFieldValues> extends FormComponentProps<TFieldValues>, Omit<NumberFormatProps, "name"> {}

const NumberFormatCustom = React.forwardRef<typeof NumberFormat, InputBaseComponentProps>((props, ref) => {
  const {onChange, defaultValue, ...other} = props;

  return (
    <NumberFormat
      {...other}
      defaultValue={defaultValue as string}
      getInputRef={ref}
      onValueChange={(values) => {
        (onChange as any)({
          target: {
            name: props.name,
            value: values.floatValue
          }
        });
      }}
      decimalSeparator=","
      thousandSeparator="."
    />
  );
});

export const FormNumberInput = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
  placeholder,
  className,
  shrink,
  disableUnderline,
  ...others
}: Props<TFieldValues> & TextFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => (
        <TextField
          error={!!error}
          {...field}
          value={field.value || ""}
          label={label}
          placeholder={placeholder}
          className={className}
          fullWidth={true}
          variant="standard"
          InputProps={{
            inputComponent: NumberFormatCustom,
            disableUnderline
          }}
          InputLabelProps={{shrink}}
          {...others}
        />
      )}
    />
  );
};
