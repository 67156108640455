import {Controller} from "react-hook-form";
import {TextField} from "@mui/material";
import {FieldValues} from "react-hook-form/dist/types";
import {FormComponentProps} from "./FormComponentProps";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {Icons} from "../icons/Icons";

const formats = {
  normalDate: "dd.MM.yyyy",
  keyboardDate: "dd.MM.yyyy"
};

export const FormDatePicker = <TFieldValues extends FieldValues>({name, control, label}: FormComponentProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={formats}>
          <DatePicker
            mask="__.__.____"
            {...field}
            value={field.value ?? null}
            label={label}
            renderInput={(params) => <TextField variant="standard" {...params} fullWidth={true} error={!!error} />}
            components={{
              OpenPickerIcon: Icons.calendar
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
};
