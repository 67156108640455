import {Box, IconButton} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {EnhancedTable} from "../../common/table/EnhancedTable";
import {HeadCell} from "../../common/table/EnhancedTableHead";
import {Form} from "../../generated/clients";
import {AdminApiClient} from "../../rest/RestHttpClient";
import * as React from "react";
import {Icons} from "../../component/icons/Icons";

interface Props {
  items: Form[];
  refresh: () => void,
}

interface FormRow {
  id: string;
  name?: string | null;
  nameEn?: string | null;
  mailValidationRequired?: boolean | null;
  icon?: JSX.Element;
}

export function FormsList({items, refresh}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const headCells: readonly HeadCell<FormRow>[] = [
    {
      id: "name",
      disablePadding: false,
      label: t("forms.list.name")
    },
    {
      id: "nameEn",
      disablePadding: false,
      label: t("forms.list.nameEn")
    },
    {
      id: "mailValidationRequired",
      disablePadding: false,
      label: t("forms.list.mailValidationRequired")
    },
    {
      id: "icon",
      disablePadding: true,
      label: ""
    }
  ];

  const onDelete = (id: string) => (e: any) => {
    e.stopPropagation()
    AdminApiClient.dropForms(id).then(() => {
      refresh();
    }).catch(console.error)
  }

  const onClick = (_: React.MouseEvent<unknown>, id: string) => {
    navigate(id);
  };


  function mapForm(form: Form): FormRow {
    return {
      id: form.guid,
      name: form.name,
      nameEn: form.nameEn,
      mailValidationRequired: form.mailValidationRequired,
      icon: <Box display="flex" flexDirection="row" columnGap={2}>{Icons.pencil()}
        <IconButton
          aria-label="delete" onClick={onDelete(form.guid)}>{Icons.clear()}</IconButton></Box>
    };
  }

  const rows: FormRow[] = items.map(mapForm);

  return (
    <Box className="customer-list">
      {
        <EnhancedTable
          rows={rows}
          headCells={headCells}
          hideCheckbox={true}
          onRowClick={onClick}
          variant="default"
        ></EnhancedTable>
      }
    </Box>
  );
}
