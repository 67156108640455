import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router";
import {useEffect, useState} from "react";
import {FormDeffinition, FormValue} from "../../generated/clients";
import {ClientApiClient} from "../../rest/RestHttpClient";
import {UploadFile} from "../forms/data/FilesContext";
import {pushNotificationHandler} from "../../common/PushNotification";
import FormRenderer from "../forms/renderer/FormRenderer";
import {Box, Card, CardContent, Typography} from "@mui/material";

export function EmbeddableFormView() {
  const {t} = useTranslation();
  const {id} = useParams();

  const [formDefinition, setFormDefinition] = useState<FormDeffinition>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (id) {
      ClientApiClient.form(id).then((res) => {
        setFormDefinition(res.data);
      });
    }
  }, [id]);

  const handleSubmit = async (values: any, files?: UploadFile[]) => {
    const formValues: FormValue[] = Object.keys(values)
      .filter((x) => values[x] != undefined)
      .map((key) => ({
        fieldName: key,
        value: String(values[key])
      }));

    if (id) {
      try {
        const {data} = await ClientApiClient.inertRecord(id, {values: formValues});
        if (files && data.id) {
          await Promise.all([...files.map((f) => ClientApiClient.uploadFile(id, data.id, f.field, f.file))]);
        }

        setSuccessMessage(data.messageDE ?? t("success"));
      } catch (e) {
        console.error(e);
        pushNotificationHandler.publish(t("error"), "error");
      }
    }
  };

  if (!formDefinition) {
    return <span />;
  }

  if (successMessage) {
    return (
      <Card
        sx={(theme) => ({
          height: "100%",
          minHeight: "400px",
          maxWidth: 800,
          background: theme.palette.grey["100"],
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center"
        })}>
        <Typography sx={(theme) => ({ma: 4, color: theme.palette.success.main})} variant="h4">
          {successMessage}
        </Typography>
      </Card>
    );
  }

  return <FormRenderer form={formDefinition} onSubmit={handleSubmit} />;
}
