import {Box, Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export function FormsHeader() {
  const {t} = useTranslation();

  return (
    <Box className="view-header">
      <h2>{t("forms.header")}</h2>
      <Box className="button-container">
        <Button component={Link} to="new" variant="contained">
          {t("forms.newForm")}
        </Button>
      </Box>
    </Box>
  );
}
